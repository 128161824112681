// React
import React, {useLocation, useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// Common
import Configs from '../../Configs';
import Header from '../../common/header';
import PunchHeader from './punchHeader';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext.js';
import DataTable from '../../widgets/dataTable';
import FilterWidget from '../../widgets/data/filterWidget.js';
import KPIStaticCard from '../../widgets/keyIndicatorItemStatic';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip.js';

// Icons
import { IoIosHelpCircleOutline } from 'react-icons/io';

// ---------------------------------------------------------------------------------------------------
function PunchHR () {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {companyID, employeeID, token, securityLevel} = useAuth();
    const {toasterMessage, setToasterMessage} = useData();

    const {punchHRReport, isPunchHRReportLoading} = useData();

    const [filteredReport, setFilteredReport] = useState(null);


    // Reporting variables
    const [compliance, setCompliance] = useState(0);
    const [companyCompliance, setCompanyCompliance] = useState(0);

    // Data tables
    let PunchReportColumns = [
      {type:'string',id: 'area', value: 'area', label: t('area'), sort: null, filter: null},
      {type:'string',id: 'subarea', value: 'subarea', label: t('subarea'), sort: null, filter: null},
      {type:'string',id: 'manager',value: 'manager', label: t('manager'), sort: null, filter: null},
      {type:'string',id: 'employee',value: 'employee', label: t('employee'), sort: null, filter: null},
      {type:'date',id: 'lastMonthSubmission',value: 'lastMonthSubmission', label: t('punch.hr.last-month-submission'), sort: null, filter: null},
    ];

  // ---------------------------------------------------------------------------------------------------
  // Page start methods
  // ---------------------------------------------------------------------------------------------------    

  useEffect (() => {     
        // Get information to populate dashboard
        if (punchHRReport) setFilteredReport(punchHRReport);

      }, [punchHRReport]);
    

  // ---------------------------------------------------------------------------------------------------
  // Data calculations
  // ---------------------------------------------------------------------------------------------------
  
  
  
  // ---------------------------------------------------------------------------------------------------
  // Filter handlers
  // ---------------------------------------------------------------------------------------------------  
  const handleFilterChange = (filteredDataInput) => {
    if (Configs.devEnvironment) console.log("[punchHR.js][handleFilterChange()] filteredDataInput: ", filteredDataInput);
    setFilteredReport(filteredDataInput);

    // Calculate compliance
    // calculateCompliance(filteredDataInput);
  };


  // ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToast = () => {
    setToasterMessage(null);
    };


  // ---------------------------------------------------------------------------------------------------
  // Page render methods
  // ---------------------------------------------------------------------------------------------------    
    return (
      <React.Fragment>
      <Header />
      < Bot/>

      <div className="main">
        <PunchHeader />
            
        <div className='flex flex-row items-start'>
        <div className="left-big-view">
              

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}


                  <div className="flex flex-col mt-2 md:ml-2 m-1">
                      <h2 className="flex text-xl text-left mb-4 ">
                        {" "}
                        <div className='flex flex-col md:flex-row justify-left'>
                          <div className='flex flex-row items-center'>
                            <Tooltip content={t('punch.hr.description')}>
                              <span className='section-title'> 📈 {t('punch.hr.title')}</span>
                            </Tooltip>
                            {i18next.language === 'es' ? 
                              <a href="https://resources.kincode.app/es/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            : 
                              <a href="https://resources.kincode.app/manager/oneOnOnes.html" target="_blank" rel="noreferrer">
                                <IoIosHelpCircleOutline className='ml-2 text-lg lg:text-xl text-gray-500 hover:text-black hover:font-bold hover:text-black hover:rounded-full ' />
                              </a>
                            }
                          </div>
                        </div>
                      </h2>
                  </div>

                  {securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelAdmin) || securityLevel?.includes(Configs.securityLevelExec) ? 
                    <React.Fragment>

                        {punchHRReport ? 
                              <React.Fragment>
                                <div className='flex flex-col'>
                                      {/* <h2 className="m-2 text-xl">
                                          {t('one-on-one.history')}
                                      </h2> */}

                                      {isPunchHRReportLoading ? 
                                        <Loader />
                                      :

                                      <div className='flex flex-col'>
                                        {/* FilterWidget */}
                                          <FilterWidget data={punchHRReport} onFilterChange={handleFilterChange} 
                                            teamTypeFilter={true} defaultTeamTypeFilter={"team"}
                                            managerFilter={true}
                                            employeeFilter={true}
                                            areaFilter={false} 
                                            subareaFilter={false}
                                            />

                                        <div className='flex flex-col lg:flex-row items-start justify-between lg:hidden'>
                                          <div className='flex flex-wrap items-end'>
                                          {compliance > 0 ? 
                                          <KPIStaticCard graph={Configs.KPIGraphAccelerator} indicator={t('oneOnOne.hr.kpi.compliance')} 
                                            data={compliance} benchmark={companyCompliance} type="Percentage" 
                                            hint={t('oneOnOne.hr.kpi.compliance.description')}/> 
                                          : 
                                            null 
                                          }
                                          </div>
                                        </div>

                                        <div className=' flex flex-row items-center justify-between '>
                                          <div className='flex flex-row items-center justify-start'>
                                            <h3 className=' text-left m-4'>
                                              {t('performance.hr-dashboard.employee-summary')}
                                            </h3> 
                                          </div>
    
                                        </div>
                                        <DataTable data={filteredReport} columnNames={PunchReportColumns} zerosForDashes={true} 
                                        // title={t('oneOnOne.hr.kpi.compliance')}
                                        />

                                      </div>
                                    }
                                </div>
                              </React.Fragment>
                            : 
                                null
                          }
                    </React.Fragment>
            : 

              <div>
                  
              </div>
            
            }
            </div>
            <div className='right-small-view'>
              {/* KPI */}
              <div className='flex flex-col lg:flex-row items-center lg:justify-center'>
                {compliance > 0 ? 
                <KPIStaticCard graph={Configs.KPIGraphAccelerator} indicator={t('oneOnOne.hr.kpi.compliance')} 
                  data={compliance} benchmark={companyCompliance} type="Percentage" 
                  hint={t('oneOnOne.hr.kpi.compliance.description')}/> 
                : 
                  null 
                }
              </div>              

            </div>  
        </div>
        </div>

      </React.Fragment>
    );
}

export default PunchHR;