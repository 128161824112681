// React
import React, {useState, useMemo, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

// Common and Widgets
import Configs from '../../Configs';
import Header from '../../common/header';
import PunchHeader from './punchHeader';

import SectionSeparator from '../../widgets/sectionSeparator';
import Bot from '../../common/support/bot';
import { useAuth } from '../../common/appContext';
import { useData } from '../../common/dataContext';
import UserListItem from '../../widgets/userListItem';
import PunchWidget from '../../widgets/daysOff/punchInOut';
import * as apiCalls from '../../common/apiCalls';

// Widgets
import { SelectColumn, SelectCellFormatter, TreeDataGrid, textEditor } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import CustomFormatters from '../../widgets/data/customFormatters';

// Toaster & Loader
import Toaster from '../../common/support/toaster';
import Loader from '../../common/support/loader';
import Tooltip from '../../common/support/tooltip';

// Icons
import { FaClock, FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';

// -------------------------------------------------------------------------------------------------------
function PunchHome () {
    const {t} = useTranslation();
    const navigate = useNavigate();

    let { encryptedParams } = useParams();
    const params = encryptedParams ? JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    
    // Employee credentials  
    const {token, employeeID, companyID} = useAuth();
    const {toasterMessage, setToasterMessage, companyPreferences} = useData();
    const {companyOrg} = useData();
    const {punchesHistorySelf, setPunchesHistorySelf, isPunchesHistorySelfLoading, selfHolidays} = useData();

    if (Configs.devEnvironment) console.log("[punchHome.js] punchesHistorySelf: ", punchesHistorySelf, selfHolidays);

    // State
    const [editMode, setEditMode] = useState(true);
    const [applicablePolicy, setApplicablePolicy] = useState(null);

    // Calendar variables
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [monthDays, setMonthDays] = useState([]);
    const dayLabels = ['widgets.calendar.monday.letter', 'widgets.calendar.tuesday.letter', 'widgets.calendar.wednesday.letter',
     'widgets.calendar.thursday.letter',
      'widgets.calendar.friday.letter', 'widgets.calendar.saturday.letter', 'widgets.calendar.sunday.letter']; // Starting with Monday

    // Data grid variables
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [expandedGroupIds, setExpandedGroupIds] = useState(new Set());
    const [showWeekends, setShowWeekends] = useState(false);
    const [isCompliant, setIsCompliant] = useState(true);

    const summaryRows = useMemo(() => {
      if (monthDays.length === 0) return [];

      var totalHours = 0;
      monthDays.forEach(day => {
        if (isNaN(parseFloat(day.totalHours))) return;
        totalHours += parseFloat(day.totalHours);
      });

      totalHours = totalHours.toFixed(2);

      return [
        {
          id: 'total_0',
          daysCount: monthDays.length,
          hoursCount: totalHours
        }
      ];
    }, [monthDays]);

    // Column width
    // Function to calculate column width based on content length
    const calculateColumnWidth = (header, rows, key, padding = 20) => {
      const maxLength = Math.max(
        header.length,
        ...rows.map(row => (row[key] ? row[key].toString().length : 0))
      );
      return maxLength * 10 + padding; // Adjust the multiplier (10) and padding as needed
    };

    // Used to toggle the expanded state of a group
    const toggleGroup = (week) => {
      setExpandedGroupIds((prevExpandedGroupIds) => {
        const newSet = new Set(prevExpandedGroupIds);
        if (newSet.has(week)) {
          newSet.delete(week); // Collapse if already expanded
        } else {
          newSet.add(week); // Expand if collapsed
        }
        return newSet; // Return the updated set
      });
    };

    // Define columns
    const punchColumns = [
      SelectColumn,
      { key: 'week', name: t('week'), editable: false, frozen: true, 
        width: calculateColumnWidth(t('week'), monthDays, 'week'),
        renderGroupCell({ childRows }) {
          const week = childRows[0].week;
          const isExpanded = expandedGroupIds.has(week); // Check if the week is in the expandedGroupIds set

          return (
            <div className="flex flex-row items-center w-full justify-between mr-4 cursor-pointer space-x-2 hover:underline">
              <strong>{week}</strong>
              {/* Render down arrow if expanded, up arrow if collapsed */}
              <button onClick={() => toggleGroup(week)}>
                {isExpanded ? <FaChevronCircleUp className='text-gray-400 hover:text-gray-800'/> : <FaChevronCircleDown className='text-gray-400 hover:text-gray-800'/>}
              </button>
            </div>
          );
        }
      },
      { key: 'label', name: t('weekday')[0]?.toUpperCase() + t('weekday')?.slice(1), editable: false, frozen: true,
         width: calculateColumnWidth(t('weekday'), monthDays, 'label')
        },
      { key: 'day', name: t('day')[0]?.toUpperCase() + t('day')?.slice(1), editable: false, frozen: true, 
        renderSummaryCell() {
          return <strong>{t('total')}</strong>;
        },
        width: calculateColumnWidth(t('day'), monthDays, 'day')
       },
      { key: 'firstPunchIn', name: t('punch.home.first-punch-in'), editable: editMode, resizable: true, renderEditCell: textEditor},
      { key: 'lastPunchOut', name: t('punch.home.last-punch-out'), editable: editMode, resizable: true, renderEditCell: textEditor},
      { key: 'restingTime', name: t('punch.home.resting-time'), editable: editMode, resizable: true, renderEditCell: textEditor,
        renderGroupCell({ childRows }) {
          return childRows.reduce((prev, row) => parseFloat(prev) + (row.restingTime ? parseFloat(row.restingTime) : 0) , 0)?.toFixed(2);
        }
       },
      { key: 'totalHours', name: t('total'), editable: false, resizable: true, renderEditCell: textEditor,
        renderSummaryCell({ row }) {
          return <strong>{row.hoursCount}</strong>;
        },
        renderGroupCell({ childRows }) {
          return childRows.reduce((prev, row) => parseFloat(prev) + (row.totalHours ? parseFloat(row.totalHours) : 0), 0)?.toFixed(2);
        }
       },
      { key: 'status', name: t('status'), editable: editMode,
        width: calculateColumnWidth(t('status'), monthDays, 'status'),
        renderCell({ row }) {
          return row.isBankHoliday || row.isHoliday ? <span>🌴</span> : row.compliant ? <span>✅</span> : <span>❌</span>;
        },
        renderGroupCell({ childRows }) {
          let rowCompliance = true;
          childRows?.forEach(row => {
            if (row.compliant === false) rowCompliance = false;
          });
          return rowCompliance ? <span>✅</span> : <span>❌</span>;
        }
      }
    ];

    // Loading
    const [isSubmitting, setIsSubmitting] = useState(false);

    // --------------------------------------------------------------------------------------------------------
    // Methods to load when page is loaded
    // --------------------------------------------------------------------------------------------------------
    useEffect (() => {
        // Get information to populate dashboard
        generateCalendarDays(currentMonth);

        defineApplicablePolicy();
                  
      }, [punchesHistorySelf]);
    
    useEffect (() => {
      // Get information to populate dashboard
    }, [expandedGroupIds]);

    // --------------------------------------------------------------------------------------------------------
    // Methods
    // --------------------------------------------------------------------------------------------------------
    const generateCalendarDays = (date) => {
      const startDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
      const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      const days = [];
      const daysToAdd = startDay === 0 ? 6 : startDay - 1; // Adjust for Monday start

      // Filter punches for the current month
      const filteredPunchesAux = punchesHistorySelf?.filter(punch => (punch?.month === date?.getMonth() + 1 && (punch?.year === date?.getFullYear())));

      if (Configs.devEnvironment) console.log("[punchHome.js][generateCalendarDays()] filteredPunchesAux: ", filteredPunchesAux);
  
      // Add actual days of the month
      for (let day = 1; day <= daysInMonth; day++) {
        const tempDate = new Date(date.getFullYear(), date.getMonth(), day);
        const tempPunchDay = filteredPunchesAux?.find(punch => punch?.day === day);

        // 
        const isFuture = tempDate > new Date();
        const isWeekendAux = (tempDate.getDay() === 0 || tempDate.getDay() === 6) ? true : false;
        const isHolidayAux = getIsHoliday(tempDate);
        const isBankHolidayAux = getIsBankHoliday(tempDate);
        const totalHoursAux = isNaN((tempPunchDay?.totalSeconds/(60*60)).toFixed(2)) ? 0 : (tempPunchDay?.totalSeconds/(60*60)).toFixed(2);
        const compliantAux = isHolidayAux || isBankHolidayAux ? true : (totalHoursAux >= (applicablePolicy?.settings?.totalHours) ? true : false);
        if (compliantAux === false) setIsCompliant(false);

        if (!showWeekends && isWeekendAux) continue;

        days.push({
          date: tempDate,
          week: t('week') + " " + getWeekNumber(tempDate),
          label: t(tempDate.toLocaleString('default', { weekday: 'long' })),
          day: day,
          firstPunchIn: new Date((tempPunchDay?.punches[tempPunchDay?.punches.length - 1]?.date))?.toLocaleTimeString() === "Invalid Date" ? null : new Date((tempPunchDay?.punches[tempPunchDay?.punches.length - 1]?.date))?.toLocaleTimeString(),
          lastPunchOut: new Date(tempPunchDay?.punches[0]?.date)?.toLocaleTimeString() === "Invalid Date" ? null : new Date(tempPunchDay?.punches[0]?.date)?.toLocaleTimeString(),
          totalHours: totalHoursAux,
          isFuture: isFuture,
          isWeekend: isWeekendAux,
          isHoliday: isHolidayAux, // Pending to implement
          isBankHoliday: isBankHolidayAux, // Pending to implement
          compliant: compliantAux
        });

      }

      if (Configs.devEnvironment) console.log("[punchHome.js][generateCalendarDays()] days: ", days);

      // Make current week expanded
      const expandedGroupIdsAux = new Set();
      setExpandedGroupIds(expandedGroupIdsAux.add(t('week') + " " + getWeekNumber(new Date())));

      // Update state
      setMonthDays([...days]);
    };

    function getWeekNumber(date) {
      // Copy the date object to avoid modifying the original
      const currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    
      // Set the date to the nearest Thursday: currentDate gets shifted to Thursday in the week containing the date
      const dayOfWeek = currentDate.getUTCDay();
      const nearestThursday = currentDate.getUTCDate() + 4 - (dayOfWeek || 7);
      currentDate.setUTCDate(nearestThursday);
    
      // Start the year on the first Thursday of January
      const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
    
      // Calculate the week number (ISO week number)
      const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);
    
      return weekNumber;
    }

    const getIsHoliday = (date) => {
      const dateAux = new Date(date);
      let holidaysAux = selfHolidays?.holidays;
      // Only approved holidays
      holidaysAux = holidaysAux?.filter (request => request?.status === Configs.daysOffStatusApproved);
      // Filter out working from home requests
      holidaysAux = holidaysAux?.filter (request => (request?.request[0]?.leaveType !== Configs.daysOffLeaveTypeWFH) && (request?.request[0]?.leaveType !== Configs.daysOffLeaveTypeWFHSick));
      let isHolidayAux = false;
      
      // Iterate through the holidays, and within each request, iterate through the days
      holidaysAux?.forEach(holiday => {
        holiday?.request?.forEach(interval => {
          const startDate = new Date(interval?.startDate);
          const endDate = new Date(interval?.endDate);
          if ((dateAux >= startDate && dateAux <= endDate) || (
            dateAux?.getDate() === startDate?.getDate() && dateAux?.getMonth() === startDate?.getMonth() && dateAux?.getFullYear() === startDate?.getFullYear()) || (
            dateAux?.getDate() === endDate?.getDate() && dateAux?.getMonth() === endDate?.getMonth() && dateAux?.getFullYear() === endDate?.getFullYear()
          )) isHolidayAux = true;
        });
      });
      
      if (Configs.devEnvironment) console.log("[punchHome.js][getIsHoliday()] date: ", dateAux, isHolidayAux, holidaysAux);
      
      return isHolidayAux ? true : false;
    };

    const getIsBankHoliday = (date) => {
      const dateAux = new Date(date);
      const bankHolidaysAux = selfHolidays?.bankHolidays;
      const isBankHoliday = selfHolidays?.bankHolidays?.find(bankHoliday => parseInt(bankHoliday?.date_day) === dateAux?.getDate() && (parseInt(bankHoliday?.date_month) === (dateAux?.getMonth() + 1)) && (parseInt(bankHoliday?.date_year) === dateAux?.getFullYear()));
      if (Configs.devEnvironment) console.log("[punchHome.js][getIsBankHoliday()] date: ", dateAux.getDate(), dateAux.getMonth()+1, dateAux.getFullYear(), isBankHoliday, bankHolidaysAux);
      return isBankHoliday ? true : false;
    }

    // Check global compliance
    const checkCompliance = (rows) => {
      let compliant = true;

      // Review every day
      rows.forEach(day => {
        // Keep first punch in, last punch out, resting time and total hours in sync
        if (day.firstPunchIn && day.lastPunchOut) {
          // Translate from HH:MM string to timestamp
          const totalHours = calculateTimeDifference(day.firstPunchIn, day.lastPunchOut);
          const restingTime = parseFloat(day.restingTime) || 0;
          day.totalHours = (totalHours - restingTime).toFixed(2);

          if (Configs.devEnvironment) console.log("[punchHome.js][checkCompliance()] day: ", day, applicablePolicy?.settings?.totalHours);
        }

        if (parseFloat(day.totalHours) < applicablePolicy?.settings?.totalHours && !day.isHoliday && !day.isBankHoliday) {
          day.compliant = false;
          compliant = false;
        } else {
          day.compliant = true;
        }
      });

      setIsCompliant(compliant);
    };

    function timeToMinutes(timeStr) {
      const [hours, minutes] = timeStr?.toString()?.split(':').map(Number);
      if (Configs.devEnvironment) console.log("[punchHome.js][timeToMinutes()] timeStr: ", timeStr, hours, minutes);
      return hours * 60 + minutes;
    }

    function calculateTimeDifference(startTime, endTime) {
      const startMinutes = timeToMinutes(startTime);
      const endMinutes = timeToMinutes(endTime);
      if (Configs.devEnvironment) console.log("[punchHome.js][calculateTimeDifference()] startTime, endTime: ", startTime, endTime, startMinutes, endMinutes);      
      return (endMinutes - startMinutes) / 60;  // Difference in hours
    }

    // Define applicable working hours
    const defineApplicablePolicy = () => {
      const preferences = companyPreferences?.modulesSettings.punchInOutConfig;

      if (Configs.devEnvironment) console.log("[punchHome.js][defineApplicablePolicy()] Punch In/out preferences: ", preferences?.general);

      let defaultPolicy =  Configs.punchInOutDefaultPolicy;
      let finalPolicy = defaultPolicy;

      if (preferences?.specific && preferences?.specific?.length > 0) {
        // look for specifics first
        let matchedPolicy = doesEmployeeMatchGroup(params ? params.employeeID : employeeID, preferences.specific);
        if (matchedPolicy) {
          finalPolicy = matchedPolicy;

        } else {
          // if it does not fit any specific, look for general
          if (preferences?.general) finalPolicy = preferences?.general;
        }

      } else {
        // look for general
        if (preferences?.general) finalPolicy = preferences?.general;
      }

      if (Configs.devEnvironment) console.log("[punchHome.js][defineApplicablePolicy()] workingHours: ", finalPolicy); 
      setApplicablePolicy(finalPolicy);
    };

    // Employee matches conditions
    function doesEmployeeMatchGroup(employeeID, specificPolicies) {
      const employee = companyOrg?.find(employee => parseInt(employee?.employeeID) === parseInt(employeeID));
      
      if (Configs.devEnvironment) console.log("[punchHome.js][doesEmployeeMatchGroup()] employee: ", employee, specificPolicies);

      let selectedPolicy = null;
      let matchedPolicies = [];
      for (const policy of specificPolicies) {
        let matchedPolicy = true;
        for (const condition of policy.conditions) {
          if (employee[condition.key] !== condition.value) {
            matchedPolicy = false;
            break;
          }
        }
        if (matchedPolicy) {
          matchedPolicies.push(policy);
        }
      }

      // Select the first matched policy
      if (matchedPolicies.length > 0) {
        selectedPolicy = matchedPolicies[0];
      }

      if (Configs.devEnvironment) console.log("[punchHome.js][doesEmployeeMatchGroup()] selectedPolicy: ", selectedPolicy);

      // Update state
      setApplicablePolicy(selectedPolicy);
    
      return selectedPolicy;
    }


    // ------------------------------------------------
    // Navigation controls
    // ------------------------------------------------
    const goToNextMonth = () => {
      const currentMonthAux = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
      if (Configs.devEnvironment) console.log("[punchHome.js][goToNextMonth()] currentMonth: ", currentMonthAux);
      setCurrentMonth(currentMonthAux);
      generateCalendarDays(currentMonthAux);
    };
    
    const goToPreviousMonth = () => {
      const currentMonthAux = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
      if (Configs.devEnvironment) console.log("[punchHome.js][goToPreviousMonth()] currentMonth: ", currentMonthAux);
      setCurrentMonth(currentMonthAux);
      generateCalendarDays(currentMonthAux);
    };
    
    const goToNextYear = () => {
      const currentMonthAux = new Date(currentMonth.getFullYear() + 1, currentMonth.getMonth(), 1);
      if (Configs.devEnvironment) console.log("[punchHome.js][goToNextYear()] currentMonth: ", currentMonthAux);
      setCurrentMonth(currentMonthAux);
      generateCalendarDays(currentMonthAux);
    };
    
    const goToPreviousYear = () => {
      const currentMonthAux = new Date(currentMonth.getFullYear() - 1, currentMonth.getMonth(), 1);
      if (Configs.devEnvironment) console.log("[punchHome.js][goToPreviousYear()] currentMonth: ", currentMonthAux);
      setCurrentMonth(currentMonthAux);
      generateCalendarDays(currentMonthAux);
    };

    // ---------------------------------------------------------------------------------------------------
    // DATA GRID METHODS
    // ---------------------------------------------------------------------------------------------------
    // rowKeyGetter is necessary for row selection
    function rowKeyGetter(row) {
      return row.date;
    }

    function handleRowSelect (input) {
      if (Configs.devEnvironment) console.log("[punchHome.js][handleRowSelect()] selectedRows: ", input);
      // Get selected rows
      const selectedRowsAux = Array.from(selectedRows);
      if (Configs.devEnvironment) console.log("[punchHome.js][handleRowSelect()] selectedRowsAux: ", selectedRowsAux);

      // Get row data
      const selectedRowData = selectedRowsAux.map(rowId => monthDays.find(row => row.id === rowId));
      if (Configs.devEnvironment) console.log("[punchHome.js][handleRowSelect()] selectedRowData: ", selectedRowData);

      setSelectedRows(new Set(input));
    }

    // Handle the row update when the user edits a cell
    const handleRowsChange = (newRows, index) => {
      if (Configs.devEnvironment) console.log("[punchHome.js][handleRowsChange()] newRows: ", newRows, index);

      // Validate the data
      let validated = false;
      // Define a regex for the time format HH:MM:SS
      const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
      const totalFormatRegex = /^([0-9]*[.])?[0-9]+$/;
      const indexAux = index.indexes[0];
      const row = newRows[indexAux];
      const value = newRows[indexAux][index.column.key];

      if (Configs.devEnvironment) console.log("[punchHome.js][handleRowsChange()] value: ", indexAux, value, timeFormatRegex.test(value), totalFormatRegex.test(value));

      // If date is in the future, do not allow editing
      if (row.isFuture) {
        setToasterMessage({type: Configs.warningToaster, message: t('punch.home.invalid-future-date')});
        return;
      }

      switch (index.column.key) {
        case 'firstPunchIn':
          if (timeFormatRegex.test(value)) {
            newRows[index.indexes[0]][index.key] = value;
            validated = true;
          } else {
            setToasterMessage({type: Configs.warningToaster, message: t('punch.home.invalid-time')});
          }
          break;
        case 'lastPunchOut':
          if (timeFormatRegex.test(value)) {
            newRows[index.indexes[0]][index.key] = value;
            validated = true;
          } else {
            setToasterMessage({type: Configs.warningToaster, message: t('punch.home.invalid-time')});
          }
          break;
        case 'restingTime':
          if (totalFormatRegex.test(value)) {
            newRows[index.indexes[0]][index.key] = value;
            validated = true;
          } else {
            setToasterMessage({type: Configs.warningToaster, message: t('punch.home.invalid-total-time')});
          }
          break;
        case 'totalHours':
          if (totalFormatRegex.test(value)) {
            newRows[index.indexes[0]][index.key] = value;
            validated = true;

            // Review compliance and update row accordingly
            if (parseFloat(value) >= applicablePolicy?.settings?.totalHours) {
              newRows[indexAux].compliant = true;
            } else {
              newRows[indexAux].compliant = false;
            }

          } else {
            setToasterMessage({type: Configs.warningToaster, message: t('punch.home.invalid-total-time')});
          }
          break;
        default:
          break;
      }

      // Return if the data is not valid
      if (!validated) return;

      // Update state with new rows
      checkCompliance(newRows);
      setMonthDays(newRows);
    };

    // Custom method to determine whether a cell is editable
    const isCellEditable = ({ row, column }) => {
      // Allow only cells in the 'value' column to be editable, or you can define custom conditions
      if(Configs.devEnvironment) console.log ("[punchHome.js][isCellEditable()] row: ", row);
      if (row?.isFuture) return false;

      return (column.key === 'firstPunchIn' || column.key === 'lastPunchOut' || column.key === 'restingTime');
    };

    function rowGrouper(rows, columnKey) {
      // if (Configs.devEnvironment) console.log("[punchHome.js][rowGrouper()] rows: ", rows);

      return Object.groupBy(rows, (r) => r[columnKey]) || [];
    }

    function setExpandedGroupIdsFunction (expandedGroupIds) {
      if (Configs.devEnvironment) console.log("[punchHome.js][setExpandedGroupIdsFunction()] expandedGroupIds: ", expandedGroupIds);
      setExpandedGroupIds(expandedGroupIds);
    }
    
    // ---------------------------------------------------------------------------------------------------
    // Submit working hours
    // ---------------------------------------------------------------------------------------------------
    async function assignStandardWorkingHours () {
      // Alert user to make sure they want to assign standard working hours
      if (!window.confirm(t('punch.home.confirm-standard-hours'))) return;

      // Assign standard working hours
      const inHour = applicablePolicy?.settings?.in;
      const outHour = applicablePolicy?.settings?.out;
      const resting = applicablePolicy?.settings?.rest;
      const total = applicablePolicy?.settings?.totalHours;

      if (Configs.devEnvironment) console.log("[punchHome.js][assignStandardWorkingHours()] inHour, outHour, resting, total: ", inHour, outHour, resting, total);

      selectedRows.forEach(rowId => {
        console.log("[punchHome.js][assignStandardWorkingHours()] rowId: ", rowId);
        const row = monthDays.find(row => {
          console.log("[punchHome.js][assignStandardWorkingHours()] row.day: ", row);
          return row.date === rowId});

        if (!row) return;
        if (row?.isWeekend || row?.isHoliday || row?.isBankHoliday || row?.isFuture) return;

        if (Configs.devEnvironment) console.log("[punchHome.js][assignStandardWorkingHours()] row: ", row);

        row.firstPunchIn = inHour;
        row.lastPunchOut = outHour;
        row.restingTime = resting;
        row.totalHours = total;
        row.compliant = true;
      }
      );

      // Update state
      setMonthDays([...monthDays]);
      checkCompliance(monthDays);

    }

    async function submitWorkingHours () {
      // Alert user to make sure they want to submit, it cannot be reverted
      if (!window.confirm(t('punch.home.confirm-submit'))) return;

      // Prepare data:
      const body = {
        companyID: companyID,
        employeeID: employeeID,
        monthSheet: monthDays
      }

      try {
        // Loading status
        setIsSubmitting(true);

        const result = await apiCalls.postPunchWorkSheet(companyID, employeeID, token, body);

        // Loading status
        setIsSubmitting(false);

        if (Configs.devEnvironment) console.log("[punchHome.js][submitWorkingHours()] result: ", result);

        if (result === 500) {
            setToasterMessage({message: t('error'), type: Configs.errorToaster});
        } else if (result === 403) {
            setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
        } else if (result === 401) {
            setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            // sessionStorage.clear();
            navigate("/");
        } else if (result === 400) {
            setToasterMessage({message: t('error'), type: Configs.warningToaster});
        } else if (result === 209) {
            setToasterMessage({message: t('error'), type: Configs.warningToaster});
        } else {
          setToasterMessage({type: Configs.successToaster, message: t('punch.home.success-submit')});
        }

      } catch (error) {
        if (Configs.devEnvironment) console.log("[punchHome.js][submitWorkingHours()] error: ", error);
        setToasterMessage({type: Configs.errorToaster, message: t('punch.home.error-submit')});
      }
    }
    
    // ---------------------------------------------------------------------------------------------------
    // Toaster methods
    // ---------------------------------------------------------------------------------------------------    
    const closeToast = () => {
      setToasterMessage(null);
    };


    // --------------------------------------------------------------------------------------------------------
    // Render methods
    // --------------------------------------------------------------------------------------------------------
    return (
      <React.Fragment>
        <Header />
        <Bot />

        <div className="main">
        <PunchHeader />

        <div className='flex flex-row items-start bg-gray-50'>
          <div className="left-big-view">

          {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  onClose={closeToast} />}
            {/* Title */}
            <div className="flex flex-col mt-2 md:ml-2 m-1">
                          <h2 className="flex text-xl text-left mb-4 ">
                              <div className='flex flex-col md:flex-row justify-left'>
                                <Tooltip content= {t('punch.home.description')} >
                                <div className='section-title'>
                                  {t('punch.home.title')}
                                  <span className="text-xs bg-yellow-400 px-3 py-1 rounded-full mx-2 font-semibold">BETA</span>

                                </div>
                                </Tooltip>
                              </div>
                          </h2>
              </div>
              {/* Employee card in case of reviwing other's punch in/out info */}
              {params ? 
                      <UserListItem user={params?.employeeName + " " + params?.employeeSurname} initials={params?.employeeName?.slice(0,1)+params?.employeeSurname?.slice(0,1)} 
                      role={params?.employeeRole} level={params?.employeeLevel}
                      area={params?.employeeArea} subarea={params?.employeeSubarea}
                      profilePicture={params?.employeeProfilePicture ? params?.employeeProfilePicture : null} />
              : null
              }

              {isPunchesHistorySelfLoading ?  
                <Loader/>
              :
                <React.Fragment>
                <div className="flex flex-col mt-8 md:ml-2 m-1">
                    {/* Table with history */}
                      <div className="flex flex-col md:ml-2 m-1">
                              <div className='flex flex-row items-center justify-center space-x-4 mb-4'>
                                <div className='flex flex-row space-x-4'>
                                  <button onClick={goToPreviousYear}  className='hover:font-bold'>{"<<"}</button>
                                  <button onClick={goToPreviousMonth} className='hover:font-bold'>{"<"}</button>
                                </div>
                                <span className='flex'>
                                  {currentMonth?.toLocaleString('default', { month: 'long' })} {currentMonth?.getFullYear()}
                                </span>
                                <div className='flex flex-row space-x-4'>
                                  <button onClick={goToNextMonth} className='hover:font-bold'> {">"} </button>
                                  <button onClick={goToNextYear} className='hover:font-bold'> {">>"} </button>
                                </div>
                              </div>

                              {/* Toolbar */}
                              <div className='flex flex-row items-center justify-between space-x-4 mb-2'>
                                {/* Assign standard working hours */}
                                <div className='flex flex-row items-center space-x-8 text-sm text-gray-800'>
                                  {/* <span className='flex flex-row items-center text-sm text-gray-800' onClick={() => setShowWeekends(!showWeekends)}>
                                    <input type="checkbox" checked={showWeekends} className='mr-1'/>
                                    {t('punch.home.show-weekends')}
                                  </span> */}
                      
                                  <div className='flex flex-row items-center space-x-2 text-sm text-gray-800'>
                                    <span className='flex flex-row items-center space-x-2 text-sm text-gray-800'>
                                      {t('selected-rows')}: {selectedRows.size}
                                    </span>
                                    {selectedRows?.size <= 0 ? 
                                      <Tooltip content={t('punch.home.empty-selection')}>
                                        <button className={`flex flex-row items-center space-x-2 text-sm rounded-lg p-2 text-gray-400 cursor-not-allowed`}
                                          disabled={selectedRows?.size <= 0 ? true : false}>
                                          <FaClock className='mr-1' />{t('punch.home.assign-standard')} 
                                        </button>
                                      </Tooltip>
                                  :
                                    <button className='flex flex-row items-center space-x-2 cursor-pointer text-sm rounded-lg hover:bg-gray-200 p-2 text-gray-800' 
                                    onClick={assignStandardWorkingHours}>
                                      <FaClock className='mr-1' /> {t('punch.home.assign-standard')}
                                      </button>
                                  }
                                  </div>
                                </div>

                                {/* Submit */}
                                <div className='flex flex-row items-center'>
                                  {isSubmitting ?
                                    <div className='flex flex-row items-center'>
                                      <Loader />
                                    </div>
                                  :
                                    !isCompliant ? 
                                      <Tooltip content={t('punch.home.submitt-disabled')}>
                                        <button disabled={!isCompliant} className='save-button' onClick={submitWorkingHours}>{t('punch.home.submit')}</button>
                                      </Tooltip>
                                    :
                                      <button disabled={!isCompliant} className='save-button' onClick={submitWorkingHours}>{t('punch.home.submit')}</button>
                                  }

                                </div>


                                </div>
                              

                            {/* Data grid */}
                            <div className="flex flex-col items-center w-full space-y-2 text-xs text-gray-800  p-4 bg-white rounded-lg shadow border">

                                {monthDays?.length > 0 && 
                                  <TreeDataGrid
                                        rowKeyGetter={rowKeyGetter}
                                        columns={punchColumns}
                                        rows={monthDays}
                                        selectedRows={selectedRows} // Tracks selected rows
                                        onSelectedRowsChange={handleRowSelect} // Updates selected rows
                                        onRowsChange={handleRowsChange}
                                        groupBy={'week'}
                                        rowGrouper={rowGrouper}
                                        expandedGroupIds={expandedGroupIds}
                                        onExpandedGroupIdsChange={setExpandedGroupIdsFunction}
                                        isCellEditable={isCellEditable} // Custom method to control which cells are editable
                                        // enableCellSelect={true}
                                        topSummaryRows={summaryRows}
                                        bottomSummaryRows={summaryRows}
                                        rowClass={(row, index) =>
                                          row?.isWeekend || index === 0 ? 'bg-gray-100' : ''
                                        }
                                        defaultColumnOptions={{
                                          // sortable: true,
                                          resizable: true
                                        }}
                                      />
                                }
                            </div>
                        </div>

                  </div>

                  </React.Fragment>
                  }
              
              <SectionSeparator />

          </div>

          {/* Calendar view */}
          <div className="right-small-view">
            <PunchWidget />
          </div>

          </div>
        </div>
      </React.Fragment>
    );
}

export default PunchHome;