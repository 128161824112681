import Configs from '../Configs';

// --------------------------------------------
// Handle API calls
// --------------------------------------------
const handleResponse = async (response, responseType = 'json') => {
    if (Configs.devEnvironment) console.log ("[apiCalls.js][handleResponse] response: ", response);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
        try {
            if (responseType === 'blob') {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.download = response.headers['Content-Disposition']?.toString()?.split("filename=")[1]; // Extract filename from header
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                URL.revokeObjectURL(url);

                return { status: response.status, message: 'File downloaded successfully' };

            } else {
                const responseBody = await response?.json();
                return responseBody;
            }

        } catch (error) {
            if (response.status === 204) {
                return 204;
            }

            const auxResponse = {
                status: response.status,
                error: error
            }
            throw auxResponse;
        }
    } else {
        // throw new Error(errorData || 'Server error'); // Customize or parse error data as needed
        return response?.status;
    }
};

const getCsrfToken = () => {
    return document?.cookie?.split('; ')?.find(row => row?.startsWith('csrfToken'))?.split('=')[1];
  };

const fetchOptions = (method, data = null, token = null, responseType = 'json') => {
    const csrfToken = getCsrfToken();

    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        headers.append('Accept', 'application/json');
        if (csrfToken) headers.append('CSRF-TOKEN', getCsrfToken());
    }

    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    const options = {
        method,
        headers,
        credentials: 'include', // if needed for cookies/cross-origin requests
    };

    options.responseType = responseType; // 'json', 'text', 'blob', etc.

    if (data) {
        options.body = JSON.stringify(data);
    }

    return options;
};

// --------------------------------------------
// API calls - export
// --------------------------------------------
// AUTHENTICATION
// AUTHENTICATE TOKEN
export const authenticateToken = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.authenticate, fetchOptions('POST', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][authenticateToken()] We could not reach the server:', error);
        return 500;
    }
}

// LOGIN - BEARER
export const logIn = async (companyID, employeeID, token, loginBody) => {
    try {
        const response = await fetch(Configs.loginAPI, fetchOptions('POST', loginBody, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][logIn()] We could not reach the server:', error);
        return 500;
    }
}

// RESET PASSWORD
export const resetPassword = async (companyID, employeeID, token, resetPasswordBody) => {
    try {
        const response = await fetch(Configs.resetPasswordAPI, fetchOptions('POST', resetPasswordBody, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][resetPassword()] We could not reach the server:', error);
        return 500;
    }
}

// CHANGE PASSWORD
export const updatePassword = async (companyID, employeeID, token, updatePasswordBody) => {
    try {
        const response = await fetch(Configs.updatePasswordAPI, fetchOptions('POST', updatePasswordBody, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][updatePassword()] We could not reach the server:', error);
        return 500;
    }
}

// LOGOUT
export const logout = async (companyID, employeeID, token) => {     
    try {
        const response = await fetch(Configs.logoutAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
             fetchOptions('POST', {}, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][logout()] We could not reach the server:', error);
        return 500;
    }
};

// --------------------------------------------
// HOME
// --------------------------------------------
// GET PENDING TO DOs
export const getPendingToDos = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetPendingToDosAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPendingToDos()] We could not reach the server:', error);
        return 500;
    }
};

// GET KEY KPIs
export const getKeyKPIs = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetKeyKPIsForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPendingToDos()] We could not reach the server:', error);
        return 500;
    }
};

// --------------------------------------------
// ORGANIGRAM
// --------------------------------------------
// CREATE EMPLOYEE
export const postNewEmployee = async (companyID, employeeID, token, employeeObject) => {
    try {
        const response = await fetch(Configs.platformPostNewEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', employeeObject, token));
        return await handleResponse(response);

    } catch (error) {
        console.error('[apiCalls.js][postNewEmployee()] We could not reach the server:', error);
        return 500;
    }
};

// EDIT EMPLOYEE
export const putEditEmployee = async (companyID, employeeID, token, employeeObject) => {
    try {
        const response = await fetch(Configs.platformPutEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('PUT', employeeObject, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][putEditEmployee()] We could not reach the server:', error);
        return 500;
    }
};

// GET ORGANIGRAM DATA
export const getOrganigramData = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetOrganigramAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
            fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOrganigram()] We could not reach the server:', error);
        return 500;
    }
}

// GET ORGANIGRAM CSV
export const getOrganigramCSV = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetOrganigramCSVAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
             fetchOptions('GET', null, token, 'blob'));
        return await handleResponse(response, 'blob');
    } catch (error) {
        console.error('[apiCalls.js][getOrganigramCSV()] We could not reach the server:', error);
        return 500;
    }
}

// SUBMIT REORGANIZATION
export const postOrganigramReorganization = async (companyID, employeeID, token, reorganizationFileID) => {
    try {
        const response = await fetch(Configs.platformPostOrganigramReorganizationAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&reorganizationFileID=" + reorganizationFileID,
         fetchOptions('POST', {
            companyID: companyID,
            employeeID: employeeID,
            reorganizationFileID: reorganizationFileID
         }, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postOrganigramReorganization()] We could not reach the server:', error);
        return 500;
    }
}


// --------------------------------------------
// DAYS OFF / TIME OFF
// --------------------------------------------
// GET TEAM PENDING REQUESTS
export const getHolidaysTeamPendingRequests = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffPendingRequestsForManagerAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getHolidaysTeamPendingRequests()] We could not reach the server:', error);
        return 500;
    }
}

export const getTeamHolidayCalendar = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetTeamHolidayCalendarAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getTeamHolidayCalendar()] We could not reach the server:', error);
        return 500;
    }
}

// POST NEW HOLIDAY REQUEST
export const postNewHolidayRequest = async (companyID, employeeID, token, holidayRequest) => {
    try {
        const response = await fetch(Configs.platformPostTimeOffAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', holidayRequest, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewHolidayRequest()] We could not reach the server:', error);
        return 500;
    }
}

// GET HOLIDAYS FOR EMPLOYEE
export const getHolidaysForEmployee = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getHolidaysForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// PERFORMANCE
// --------------------------------------------
// GET PERSONAL PERFORMANCE DATA
export const getPersonalPerformanceData = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceCampaignsHistoryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPersonalPerformanceData()] We could not reach the server:', error);
        return 500;
    }
}

// GET LIVE PERFORMANCE CAMPAIGNS
export const getLivePerformanceCampaigns = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetLivePerformanceCampaignsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getLivePerformanceCampaigns()] We could not reach the server:', error);
        return 500;
    }
}

// EMPLOYEE DRAFT
export const getPerformanceReviewDraft = async (companyID, employeeID, token, campaignID) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceDraftsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID=" + campaignID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewDraft()] We could not reach the server:', error);
        return 500;
    }
}

// MANAGER DRAFT
export const getPerformanceReviewManagerDraft = async (companyID, employeeID, token, campaignID, managerID) => {
    try {
        const response = await fetch(Configs.platformGetManagerPerformanceDraftsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID=" + campaignID + "&managerID=" + managerID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewManagerDraft()] We could not reach the server:', error);
        return 500;
    }
}

// PERFORMANCE HISTORY
export const getPerformanceReviewHistory = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceCampaignsHistoryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewHistory()] We could not reach the server:', error);
        return 500;
    }
}

// GET FEEDBACK PROVIDERS OF CAMPAIGN
export const getPerformanceFeedbackProviders  = async (companyID, employee, token, campaignID) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceFeedbackProvidersAPI + "?companyID=" + companyID + "&employeeID=" + employee + "&campaignID=" + campaignID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getFeedbackProviders()] We could not reach the server:', error);
        return 500;
    }
}

// GET HR LIVE CAMPAIGN REPORT
export const getPerformanceReviewStatusByCompany = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceReviewStatusByCompanyAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewStatusByCompany()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// CALIBRATION
// --------------------------------------------
export const getCalibrationData = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetCalibrationCampaignsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCalibrationData()] We could not reach the server:', error);
        return 500;
    }
}

export const getCalibrationCampaignsHistory = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetCalibrationCampaignsHistoryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCalibrationCampaignsHistory()] We could not reach the server:', error);
        return 500;
    }
}   


// --------------------------------------------
// RECOGNITION
// --------------------------------------------
export const getRecognitionDataForCompany = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetRecognitionDataForCompanyAPI + "?companyID=" + companyID +"&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getRecognitionDataForCompany()] We could not reach the server:', error);
        return 500;
    }
}

export const getRecognitionLeaderboardsForCompany = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetRecognitionLeaderboardsForCompanyAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
            fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getRecognitionLeaderboardsForCompany()] We could not reach the server:', error);
        return 500;
    }
}

export const getPublicSingleRecognition = async (recognitionID) => {
    try {
        const response = await fetch(Configs.platformGetPublicSingleRecognitionAPI + "?recognitionID=" + recognitionID, fetchOptions('GET', null, process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPublicSingleRecognition()] We could not reach the server:', error);
        return 500;
    }
}

export const postPlus1Recognition = async (companyID, employeeID, token, recognitionID, body) => {
    try {
        const response = await fetch(Configs.platformPostPlus1RecognitionAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&recognitionID=" + recognitionID, fetchOptions('POST', body, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postPlus1Recognition()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// PULSE
// --------------------------------------------
// GET PULSE REPORTING DATA (i.e.: aggregated by manager data) - so we can filter by team - and also by category, subcategory of questions
export const getPulseReportingData = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.pulseReportingDataAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        if (Configs.devEnvironment) console.log("[apiCalls.js][getPulseReportingData()] response: ", response);
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseReportingData()] We could not reach the server:', error);
        return 500;
    }
}

export const getPulseHomeGraphFrontendData = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetPulseHomeGraphFrontendDataAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseHomeGraphFrontendData()] We could not reach the server:', error);
        return 500;
    }
}

export const getPulseKeyIndicators = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetPulseKeyIndicatorsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseKeyIndicators()] We could not reach the server:', error);
        return 500;
    }
}

// GET DAILY QUESTION
export const getPulseDailyQuestion = async (companyID, employeeID, token, questionDate) => {
    try {
        const response = await fetch(Configs.platformPulseGetDailyQuestionAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&questionDate=" + questionDate
        , fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getDailyQuestion()] We could not reach the server:', error);
        return 500;
    }
}

// GET ALL PULSE QUESTIONS
export const getAllPulseQuestions = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.pulseGetAllQuestionsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getAllPulseQuestions()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// 1:1s - MEETINGS
// --------------------------------------------
export const getSpace = async (companyID, employeeID, token, managerID) => {
    try {
        const response = await fetch(Configs.platformGetMeetingsByEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&managerID=" + managerID, fetchOptions('GET', null, token));                
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getSpace()] We could not reach the server:', error);
        return 500;
    }
}

export const postNewMeeting = async (companyID, employeeID, token, meetingData) => {
    try {
        const response = await fetch(Configs.platformPostMeetingAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', meetingData, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewMeeting()] We could not reach the server:', error);
        return 500;
    }
}

export const getOneOnOnesReport = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetOneOnOnesReportAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOneOnOnesReport()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// ONBOARDING
// --------------------------------------------
export const getOnboardingReportingData = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingReportingDataAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseReportingData()] We could not reach the server:', error);
        return 500;
    }
}

export const getOnboardingEligibleEmployees = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingEligibleCandidatesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingEligibleEmployees()] We could not reach the server:', error);
        return 500;
    }
}

export const getOnboardingTemplates = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingPlanTemplatesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingTemplates()] We could not reach the server:', error);
        return 500;
    }
}

export const postOnboardingTemplate = async (companyID, employeeID, token, data) => {

    try {
        const response = await fetch(Configs.platformPostOnboardingTemplateAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', data, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postOnboardingTemplate()] We could not reach the server:', error);
        return 500;
    }
}

export const deleteOnboardingTemplate = async (companyID, employeeID, token, data) => {
    try {
        const response = await fetch(Configs.platformDeleteOnboardingPlanTemplateAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('DELETE', data, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][deleteOnboardingTemplate()] We could not reach the server:', error);
        return 500;
    }
}

export const getOnboardingPlanForEmployee = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingPlanForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingPlanForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

export const postOnboardingPlanSubsectionComplete = async (companyID, employeeID, token, data) => {
    try {
        const response = await fetch(Configs.platformPostOnboardingPlanSubsectionCompleteAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', data, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postOnboardingPlanSubsectionComplete()] We could not reach the server:', error);
        return 500;
    }
}

export const deleteOnboardingPlan = async (companyID, employeeID, token, data) => {
    try {
        const response = await fetch(Configs.platformDeleteOnboardingPlanAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('DELETE', data, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][deleteOnboardingPlan()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// CAREER
// --------------------------------------------
export const getJobLevellingGuidelinesData = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetCareerJobGuidelinesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getJobLevellingGuidelinesData()] We could not reach the server:', error);
        return 500;
    }
}

export const postJobLevellingGuideline = async (companyID, employeeID, token, jobGuidelineData) => {
    try {
        const response = await fetch(Configs.platformPostCareerJobGuidelineAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', jobGuidelineData, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postJobLevelleingGuideline()] We could not reach the server:', error);
        return 500;
    }
}

export const putJobLevellingGuideline = async (companyID, employeeID, token, jobGuidelineData) => {
    try {
        const response = await fetch(Configs.platformPutCareerJobGuidelineAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('PUT', jobGuidelineData, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][putJobLevellingGuideline()] We could not reach the server:', error);
        return 500;
    }
}

export const postNewCareerPathForEmployee = async (companyID, employeeID, token, careerPathData) => {
    try {
        const response = await fetch(Configs.platformPostCareerPathForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', careerPathData, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewCareerPathForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

export const postNewCareerConversation = async (companyID, employeeID, token, careerConversationData) => {
    try {
        const response = await fetch(Configs.platformPostCareerConversationAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
         fetchOptions('POST', careerConversationData, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewCareerConversation()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// PUNCH 
// --------------------------------------------
export const getPunchHRReport = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffHRPunchReportAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPunchHRReport()] We could not reach the server:', error);
        return 500;
    }
}

export const getPunchStatusForEmployee = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffPunchStatusAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPunchStatusForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

export const postPunchWorkSheet = async (companyID, employeeID, token, body) => {
    try {
        const response = await fetch(Configs.platformPostTimeOffPunchSubmitMonthSheetAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', body, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postPunchWorkSheet()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// FILES MANAGEMENT
// --------------------------------------------
export const getPublicURLForFile = async (companyID, employeeID, token, fileKey) => {
    try {
        const response = await fetch(Configs.platformGetPublicURLForFileAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&fileKey=" + fileKey, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPublicURLForFile()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// SUPER ADMIN
// --------------------------------------------
export const getCCOaaSReport = async (companyID, employeeID, token) => {
    try {
        const response = await fetch(Configs.platformGetCCOaaSReportAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCCOaaSReport()] We could not reach the server:', error);
        return 500;
    }
}

export const getCCOaaSCompanyDetailsAIAnalysis = async (companyID, employeeID, token, selectedCompanyID = 1) => {
    try {
        const response = await fetch(Configs.platformGetCCOaaSCompanyDetailsAIAnalysisAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&selectedCompanyID=" + selectedCompanyID, fetchOptions('GET', null, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCCOaaSCompanyDetailsAIAnalysis()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// AI
// --------------------------------------------
export const getOnboardingSurveysAISummary = async (companyID, employeeID, token, surveyData) => {
    try {
        const response = await fetch(Configs.platformPOSTOnboardingSurveysAISummaryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, fetchOptions('POST', surveyData, token));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingSurveysAISummary()] We could not reach the server:', error);
        return 500;
    }
}
